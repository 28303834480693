* {
	margin: 0;
	padding: 0;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	scroll-behavior: smooth;
}

@font-face {
	font-family: 'Ubuntu Regular';
	src: url('./fonts/Ubuntu-R.ttf');
}

@font-face {
	font-family: 'Ubuntu Bold';
	src: url('./fonts/Ubuntu-B.ttf');
}

p {
	font-weight: 400;
	font-family: 'Ubuntu Regular';
}

b {
	font-family: 'Ubuntu Bold';
}

.content {
	max-width: 100%;
	overflow: hidden;
}

.section {
	margin-bottom: 3rem;
	padding: 3rem 0;
	font-family: 'Ubuntu', sans-serif;
}

.section.about,
.section.welcome {
	margin-bottom: 0;
}

.section.download {
	margin: 0;
}

.gray {
	background: #f3f3f3;
}

.dark {
	background: #1b1b1b;
	color: #fff;
}

.dark a {
	color: inherit;
	text-decoration: none;
}

.dark a:hover {
	opacity: 0.8;
}

.download .links a {
	display: block;
}

.with-txt {
	margin-top: 5rem;
}

footer {
	padding: 2rem 0;
}

footer b,
.gray b {
	font-size: 115%;
}

.red {
	color: #f88a1a;
}

.adress p:not(:first-child) {
	margin-bottom: 0;
}

.blue {
	color: #000033;
}

h2 {
	margin-bottom: 3rem;
	font-size: 3rem;
}

.welcome {
	background: url('./img/main-bg.jpg') no-repeat center;
	background-size: cover;
	position: relative;
	padding-bottom: 46%;
	width: 100%;
	height: 0;
}

.welcome .container-fluid {
	margin-left: 8%;
	max-width: 38%;
}

footer .container-fluid {
	max-width: 80%;
	margin: auto;
}

.row img {
	max-width: 100%;
}

.logos {
	margin-bottom: 5rem;
}

.download .links {
	margin-top: 3.5rem;
}

.download .links img {
	height: 3.5rem;
	width: auto;
}

.welcome h1 {
	font-size: 3.5rem;
	font-weight: 900;
	max-width: 80%;
}

h2 {
	font-weight: 900;
	font-size: 2.7rem;
}

.offset {
	margin-left: 2%;
}

.welcome-red-but {
	-webkit-appearance: none;
	border: none;
	outline: none;
	background: rgb(248, 138, 26);
	color: #fff;
	text-shadow: 0.777px 0.629px 0px #000;
	height: 3.5rem;
	padding: 0 2.5rem;
	border-radius: 2rem;
	text-transform: uppercase;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	margin-top: 3rem;
	-webkit-transition: all 0.2s;
	-o-transition: all 0.2s;
	transition: all 0.2s;
}

.more b a:hover {
	color: inherit;
}

.welcome-red-but:active,
.welcome-red-but:focus {
	outline: none;
}

.welcome-red-but:hover,
.links a:hover img,
.welcome a:hover img {
	-webkit-transform: translate(0, 5px);
	-ms-transform: translate(0, 5px);
	transform: translate(0, 5px);
	-webkit-transition: all 0.2s;
	-o-transition: all 0.2s;
	transition: all 0.2s;
	outline: none;
}

.block {
	border-radius: 10px;
	padding: 2rem;
	padding-top: 3rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-shadow: 16px 14px 20px #bdbdbd;
	box-shadow: 16px 14px 20px #bdbdbd;
}

.block img {
	height: 4rem;
	width: auto;
	margin-bottom: 1rem;
}

@media screen and (max-width: 1650px) {
	h2 {
		font-size: 2.3rem;
	}
	.welcome h1 {
		font-size: 3rem;
	}
}

@media screen and (max-width: 1583px) {
	.welcome h1 {
		font-size: 2.5rem;
	}
}

@media screen and (max-width: 1332px) {
	.welcome h1 {
		font-size: 2.2rem;
	}
}

@media screen and (max-width: 1232px) {
	footer p {
		font-size: 0.85rem;
	}
	footer .container-fluid {
		max-width: 90%;
		margin: auto;
	}
}

@media screen and (max-width: 1200px) {
	.welcome h1 {
		font-size: 2.5rem;
		max-width: 85%;
	}
	.section.welcome {
		padding-bottom: 65%;
		margin-bottom: -10%;
		background-position-x: 10%;
	}
	.welcome .container-fluid {
		max-width: 45%;
	}
	.logos {
		margin-bottom: 3rem;
	}
	.block {
		height: 15rem;
		font-size: 1rem;
	}
	.download .links {
		margin-top: 2.5rem;
	}
	.download .links img {
		height: 2.5rem;
	}
	footer b {
		display: block;
	}
}

@media screen and (max-width: 1063px) {
	.welcome .container-fluid {
		max-width: 50%;
	}
	.welcome h1 {
		max-width: 82%;
	}
}

@media screen and (max-width: 992px) {
	.section.welcome {
		padding-bottom: 85%;
		margin-bottom: -15%;
		background-position-x: 40%;
	}
	footer .adress,
	footer .adress + div {
		margin-bottom: 1rem;
	}
	footer p {
		font-size: 1rem;
		margin-bottom: 0;
	}
	footer b {
		display: inline;
		margin-right: 0.5rem;
	}
	.welcome .container-fluid {
		max-width: 60%;
		margin-left: 4%;
	}
	.blocks-prof {
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		margin-top: 2rem;
	}
	.block {
		font-size: 0.89rem;
	}
	.download .row {
		flex-wrap: wrap-reverse;
	}
	.with-txt {
		margin-top: 0;
		margin-bottom: 3rem;
	}
}

@media screen and (max-width: 831px) {
	.section.welcome {
		padding-bottom: 90%;
		margin-bottom: -15%;
		background-position-x: 35%;
	}
	.welcome h1 {
		max-width: 88%;
	}
}

@media screen and (max-width: 781px) {
	.section.welcome {
		padding-bottom: 100%;
		margin-bottom: -15%;
		background-position-x: 32%;
	}
	.welcome .container-fluid {
		max-width: 75%;
	}
	.welcome h1 {
		max-width: 73%;
	}
}

@media screen and (max-width: 768px) {
	.logos.row {
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		max-width: 75%;
	}
	.logos .col-md-3 {
		-webkit-box-flex: 40%;
		-ms-flex: 40%;
		flex: 40%;
	}
	.logos .col-md-4 {
		-webkit-box-flex: 55%;
		-ms-flex: 55%;
		flex: 55%;
	}
	.section.welcome {
		padding-bottom: 110%;
		margin-bottom: -24%;
		background-position-x: 37%;
	}
	.blocks-prof {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.block {
		-webkit-box-flex: 100%;
		-ms-flex: 100% 1;
		flex: 100% 1;
		max-width: 75%;
		margin: 1rem 0;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		height: auto;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		font-size: 1.1rem;
	}
	.block img {
		width: 5rem;
		height: auto;
		margin-right: 2rem;
	}
	.download .links {
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}
	.download .links > div {
		-webkit-box-flex: 42%;
		-ms-flex: 42%;
		flex: 42%;
		max-width: 31%;
	}
}

@media screen and (max-width: 736px) {
	.welcome h1 {
		font-size: 2.3rem;
	}
}

@media screen and (max-width: 681px) {
	.section.welcome {
		padding-bottom: 115%;
		margin-bottom: -27%;
		background-position-x: 37%;
	}
	.welcome .container-fluid {
		max-width: 80%;
	}
	.welcome h1 {
		font-size: 2.1rem;
	}
}

@media screen and (max-width: 629px) {
	.welcome-red-but {
		padding: 0 2rem;
		margin-top: 2rem;
	}
	.logos.row {
		max-width: 65%;
	}
}

@media screen and (max-width: 586px) {
	.welcome h1 {
		font-size: 1.8rem;
		max-width: 75%;
	}
	.welcome-red-but {
		height: 2.8rem;
		padding: 0 1.5rem;
	}
	h2 {
		font-size: 2rem;
	}
	.section:not(.about):not(.gray):not(.welcome) {
		padding-top: 0;
	}
	.block {
		max-width: 100%;
	}
}

@media screen and (max-width: 506px) {
	.welcome .container-fluid {
		max-width: 90%;
		margin: auto;
	}
	.section.welcome {
		padding-bottom: 61%;
		background-position-x: 6%;
		margin-bottom: 31%;
	}
	.logos.row {
		max-width: 71%;
		margin-left: -11%;
	}
	.welcome h1 {
		font-size: 2.6rem;
		max-width: 100%;
		margin-top: 9rem;
	}
	.welcome-red-but {
		display: block;
		margin: 2rem auto 0;
	}
	.blocks-prof {
		margin-top: 0;
	}
}

@media screen and (max-width: 474px) {
	.welcome h1 {
		font-size: 2.2rem;
	}
	.section.welcome {
		padding-bottom: 67%;
		background-position-x: 6%;
		margin-bottom: 31%;
	}
	.download .links > div {
		-webkit-box-flex: 50%;
		-ms-flex: 50%;
		flex: 50%;
		max-width: 45%;
	}
	.download .links img {
		height: 100%;
		width: 100%;
		-o-object-fit: contain;
		object-fit: contain;
	}
}

@media screen and (max-width: 406px) {
	.welcome h1 {
		font-size: 2rem;
	}
	.logos.row {
		max-width: 85%;
		margin-left: -11%;
	}
	.section.welcome {
		padding-bottom: 72%;
	}
	h2 {
		font-size: 1.8rem;
		margin-bottom: 2rem;
	}
}

@media screen and (max-width: 376px) {
	.welcome .container-fluid {
		max-width: 95%;
	}
	.section.welcome {
		padding-bottom: 79%;
		background-position-x: 20%;
		margin-bottom: 40%;
	}
	.block {
		font-size: 1rem;
	}
}

@media screen and (max-width: 354px) {
	.welcome h1 {
		font-size: 1.8rem;
	}
	.logos.row {
		max-width: 60%;
		-ms-flex-wrap: wrap-reverse;
		flex-wrap: wrap-reverse;
		margin-top: -2rem;
	}
	.logos.row > div {
		-webkit-box-flex: 100%;
		-ms-flex: 100%;
		flex: 100%;
	}
	.logos .col-md-3 {
		margin-top: 2rem;
		max-width: 64%;
	}
	.welcome h1 {
		margin-top: 0.5rem;
	}
	.section.welcome {
		margin-bottom: 35%;
	}
	h2 {
		font-size: 1.5rem;
		margin-bottom: 1.5rem;
	}
	.block img {
		width: 4rem;
		margin-right: 1.2rem;
	}
	footer p {
		font-size: 0.85rem;
	}
}
@media screen and (max-width: 330px) {
	.section.welcome {
		margin-bottom: 50%;
	}
}

.example {
	display: -ms-grid;
	display: grid;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		from(white),
		to(black)
	);
	background: -o-linear-gradient(top, white, black);
	background: linear-gradient(to bottom, white, black);
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.block p {
		display: table-cell;
	}
	.download .links img {
		height: 50px;
		width: 162px;
	}
}
